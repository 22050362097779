import React from 'react';
import TextSectionBlock, { type TextSectionBlockProps } from 'libs/wordpress/content/Blocks/TextSection';
import FullWidth from '@sportson/core-web/App/layouts/FullWidth';

export interface TextSectionModuleProps extends Record<string, unknown> {
    data: TextSectionBlockProps;
}

const TextSectionModule: React.FunctionComponent<TextSectionModuleProps> = ({ data, ...rest }) => (
    <FullWidth p={['40px 12px', null, '24px 16px', null, '40px']}>
        <TextSectionBlock {...data} />
    </FullWidth>
);

export default TextSectionModule;
