import styled from 'libs/styled';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { type WordpressACFWysiwyg } from 'libs/wordpress/types/acf/fields';
import Background from '@sportson/core-web/components/Background';
import Button, { type ButtonProps } from 'components/Button';
import Text from 'components/Text';
import { type BackgroundColorProps } from '@sportson/core-web/components/BackgroundColor';
import { type BackgroundImageProps } from '@sportson/core-web/components/BackgroundImage';
import { type BackgroundVideoProps } from '@sportson/core-web/components/BackgroundVideo';
import { ArrowIcon } from 'assets/media/icons/ArrowIcon';
import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';
import { type WordpressTextColor } from 'libs/wordpress/types/contentSystem/settings';

export interface TextSectionComponentHeadingSection {
    layout: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    text: WordpressACFWysiwyg;
    color?: CssVariable<CssColorKey>;
}

export interface TextSectionComponentPreambleSection {
    layout: 'preamble';
    text: WordpressACFWysiwyg;
    color?: CssVariable<CssColorKey>;
}

export interface TextSectionComponentQuoteSection {
    layout: 'quote';
    text: WordpressACFWysiwyg;
    color?: CssVariable<CssColorKey>;
}

export interface TextSectionComponentTextContentSection {
    layout: 'textContent';
    text: WordpressACFWysiwyg;
    color?: CssVariable<CssColorKey>;
}

export type TextSectionComponentSection =
    | TextSectionComponentHeadingSection
    | TextSectionComponentPreambleSection
    | TextSectionComponentQuoteSection
    | TextSectionComponentTextContentSection;

export type TextSectionComponentAlignmentProps = 'flex-start' | 'center' | 'flex-end';

export interface TextSectionComponentProps {
    sections: Array<TextSectionComponentSection>;
    backgroundColor?: BackgroundColorProps;
    backgroundImage?: BackgroundImageProps;
    backgroundVideo?: BackgroundVideoProps;
    buttons?: Array<ButtonProps>;
    contentAlignment?: TextSectionComponentAlignmentProps;
    textColor?: CssVariable<CssColorKey>;
}

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 40px;
    border-radius: 4px;
    overflow: hidden;
`;

const BackgroundWrapper = styled('div')`
    position: absolute;
    display: flex;
    inset: 0;
    mix-blend-mode: multiply;
    z-index: -1;
`;

const ColorInheritanceDiv = styled('div')`
    --local-color: ${(props) => props.color};
`;

const SectionWrapper = styled('section')`
    width: 100%;
    display: flex;
    flex-direction: column;

    h1 {
        padding: 0 0 32px 0;
    }

    h2 {
        padding: 16px 0;
    }

    h3 {
        padding: 16px 0;
    }

    h4 {
        padding: 16px 0;
    }

    h5 {
        padding: 16px 0;
    }

    p {
        padding: 0;
        margin: 0 0 1em 0;
    }

    strong {
        font-family: 'Emeric Medium', 'Emeric Medium Fallback';
        font-weight: 600;
    }

    blockquote {
        margin: 40px 0;
        padding: 0;

        &::before,
        &::after {
            content: '"';
        }
    }

    ul,
    ol {
        font-family: 'Emeric Book', 'Emeric Book Fallback';
        padding: 0 0 0 32px;

        li {
            padding: 0;
            margin: 0;

            + li {
                margin-top: 8px;
            }
        }

        p {
            margin: 0;
            padding: 0;
        }
    }

    ul {
        li {
            list-style-type: none;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: -24px;
                top: 8px;
                width: 6px;
                height: 6px;
                background-color: var(--local-color, #000);
                border-radius: 2px;
            }
        }
    }

    ol {
        counter-reset: number;
        list-style-type: none;

        li {
            position: relative;
            counter-increment: number;

            &::marker {
                display: none;
            }

            &::before {
                position: absolute;
                left: -24px;
                font-size: 16px;
                line-height: 150%;
                content: counter(number) '.';
                color: inherit;
                display: inline-block;
            }
        }
    }
`;

const headingStyles = {
    default: ['Content/40_120_0_500'],
    h1: ['Content/40_120_0_500', null, null, 'Content/56_120_0_500'],
    h2: ['Content/32_120_0_500', null, null, 'Content/40_120_0_500'],
    h3: ['Content/24_130_0_450', null, null, 'Content/24_130_0_450'],
    h4: ['Content/20_130_0_450', null, null, 'Content/20_130_0_450'],
    h5: ['Content/20_130_0_450', null, null, 'UI/16_100_0_450'],
};

const ButtonWrapper = styled('div')``;

const TextSectionBlock = ({
    sections,
    backgroundColor,
    backgroundImage,
    backgroundVideo,
    buttons,
    contentAlignment,
    textColor,
}: TextSectionComponentProps) => {
    const hasBackground = backgroundColor?.backgroundColor || backgroundImage || backgroundVideo;

    return (
        <Wrapper
            backgroundColor={!hasBackground && 'var(--color-brand-yellow)'}
            p={['40px 16px', null, null, null, '40px 40px 64px']}
            justifyContent={contentAlignment ?? 'center'}
        >
            <SectionWrapper width={['100%', null, null, '56%']} alignSelf={contentAlignment ?? 'center'}>
                {sections.map((section, index) => {
                    if (
                        section.layout === 'h1' ||
                        section.layout === 'h2' ||
                        section.layout === 'h3' ||
                        section.layout === 'h4' ||
                        section.layout === 'h5'
                    ) {
                        const { text, color } = section;
                        return (
                            <Wysiwyg
                                data={text}
                                TextComponent={(props) => (
                                    <Text
                                        color={color || textColor}
                                        $as={section.layout}
                                        typography={headingStyles[section.layout]}
                                        {...props}
                                    />
                                )}
                            />
                        );
                    }
                    if (section.layout === 'preamble') {
                        const { text, color } = section;
                        return (
                            <Wysiwyg
                                data={text}
                                TextComponent={(props) => (
                                    <Text color={color || textColor} typography="Content/20_130_0_450" {...props} />
                                )}
                            />
                        );
                    }

                    if (section.layout === 'textContent') {
                        const { text, color } = section;
                        return (
                            <ColorInheritanceDiv color={color || textColor}>
                                <Wysiwyg
                                    data={text}
                                    TextComponent={(props) => (
                                        <Text
                                            color={color || textColor}
                                            textAlign="left"
                                            $as="p"
                                            typography="Content/16_150_0_350"
                                            {...props}
                                        />
                                    )}
                                />
                            </ColorInheritanceDiv>
                        );
                    }

                    if (section.layout === 'quote') {
                        const { text, color } = section;
                        return (
                            <Wysiwyg
                                data={text}
                                TextComponent={(props) => (
                                    <Text
                                        color={color || textColor}
                                        textAlign="left"
                                        $as="blockquote"
                                        typography={['Content/24_130_0_450', null, null, 'Content/32_130_0_500']}
                                        {...props}
                                    />
                                )}
                            />
                        );
                    }
                })}
                {buttons?.map((button, index) => (
                    <ButtonWrapper>
                        <Button url={button.url} theme={button.theme ?? 'primary'}>
                            <>
                                {button.children}
                                <ArrowIcon />
                            </>
                        </Button>
                    </ButtonWrapper>
                ))}
            </SectionWrapper>

            {hasBackground && (
                <BackgroundWrapper>
                    <Background
                        backgroundVideo={backgroundVideo}
                        backgroundImage={backgroundImage}
                        backgroundColor={backgroundColor}
                    />
                </BackgroundWrapper>
            )}
        </Wrapper>
    );
};

export default TextSectionBlock;
