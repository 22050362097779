import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';

import { type WordpressACFWysiwyg } from '../types/acf/fields';
import { type WordpressPreamble } from '../types/contentSystem/elements';
import { type WordpressPreambleGroup } from '../types/contentSystem/groups';
import transformTextColor from './transformTextColor';

export interface TransformPreambleOutput {
    text: WordpressACFWysiwyg;
    color?: CssVariable<CssColorKey>;
    size?: string;
    weight?: string;
    font?: string;
}

export const transformPreamble = (input: WordpressPreamble): TransformPreambleOutput => {
    const output: TransformPreambleOutput = {
        text: input.text,
    };

    if (input.textColor && input.textColor !== 'default') {
        output.color = transformTextColor(input.textColor);
    }

    if (input.textSize && input.textSize !== 'default') {
        output.size = input.textSize;
    }

    if (input.textWeight && input.textWeight !== 'default') {
        output.weight = input.textWeight;
    }

    if (input.font && input.font !== 'default') {
        output.font = input.font;
    }

    return output;
};

export const transformPreambleGroup = (group: WordpressPreambleGroup, device: 'mobile' | 'desktop') => {
    if (device === 'mobile' && group.customizeMobile && group.mobilePreamble) {
        return transformPreamble(group.mobilePreamble);
    }
    return transformPreamble(group.desktopPreamble);
};
