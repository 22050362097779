import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import {
    type WordpressBackgroundSelection,
    type WordpressFlexibleNavigation,
    type WordpressPreambleGroup,
    type WordpressTextSectionHeadingGroup,
    type WordpressTextContentGroup,
} from 'libs/wordpress/types/contentSystem/groups';
import {
    type WordpressTextColor,
    type WordpressHorizontalAlignment,
} from 'libs/wordpress/types/contentSystem/settings';
import { transformBackgroundGroup } from 'libs/wordpress/utils/transformBackground';
import { transformLinkButton } from 'libs/wordpress/utils/transformButton';
import { type BackgroundColorProps } from '@sportson/core-web/components/BackgroundColor';
import { type BackgroundImageProps } from '@sportson/core-web/components/BackgroundImage';
import { type BackgroundVideoProps } from '@sportson/core-web/components/BackgroundVideo';
import TextSectionComponent, {
    type TextSectionComponentAlignmentProps,
    type TextSectionComponentHeadingSection,
    type TextSectionComponentPreambleSection,
    type TextSectionComponentProps,
    type TextSectionComponentQuoteSection,
    type TextSectionComponentTextContentSection,
} from 'content/TextSection';
import { transformTextSectionHeadingGroup } from '../../utils/transformHeading';
import { transformTextContentGroup } from '../../utils/transformTextContent';
import transformTextColor from '../../utils/transformTextColor';
import { transformPreambleGroup } from '../../utils/transformPreamble';
import { type WordpressStandardModuleSettings } from '../../types/contentSystem/groups';

interface WordpressTextSectionHeadingSection {
    layout: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    textSectionHeadings: WordpressTextSectionHeadingGroup;
}

interface WordpressTextSectionPreambleSection {
    layout: 'preamble';
    preamble: WordpressPreambleGroup;
}

interface WordpressTextSectionQuoteSection {
    layout: 'quote';
    quote: WordpressTextContentGroup;
}

interface WordpressTextSectionTextSectionSection {
    layout: 'text_content';
    textContent: WordpressTextContentGroup;
}

export type TextSectionSection =
    | WordpressTextSectionHeadingSection
    | WordpressTextSectionPreambleSection
    | WordpressTextSectionQuoteSection
    | WordpressTextSectionTextSectionSection;

export interface WordpressTextSectionBlock {
    contentHorizontalAlignment: WordpressHorizontalAlignment;
    content: {
        sections: TextSectionSection[];
    };
    background: WordpressBackgroundSelection;
    buttons: WordpressFlexibleNavigation;
    textColor: WordpressTextColor;
}
export interface TextSectionBlockProps extends Record<string, unknown> {
    data: WordpressTextSectionBlock;
    moduleSettings: WordpressStandardModuleSettings;
}

const hAlignmentMapper: Record<WordpressHorizontalAlignment, TextSectionComponentAlignmentProps | undefined> = {
    default: undefined,
    right: 'flex-end',
    center: 'center',
    left: 'flex-start',
};

const transformTextSectionHeading = (
    section: WordpressTextSectionHeadingSection,
    device: 'mobile' | 'desktop',
): TextSectionComponentHeadingSection => {
    const { text: heading, color } = transformTextSectionHeadingGroup(section.textSectionHeadings, device);
    return {
        layout: section.layout,
        text: heading,
        color,
    };
};
const transformTextSectionPreamble = (
    section: WordpressTextSectionPreambleSection,
    device: 'mobile' | 'desktop',
): TextSectionComponentPreambleSection => {
    const { text, color } = transformPreambleGroup(section.preamble, device);
    return {
        layout: 'preamble',
        text,
        color,
    };
};

const transformTextSectionQuote = (
    section: WordpressTextSectionQuoteSection,
    device: 'mobile' | 'desktop',
): TextSectionComponentQuoteSection => {
    const { text, color } = transformTextContentGroup(section.quote, device);
    return {
        layout: 'quote',
        text,
        color,
    };
};

const transformTextSectionTextContent = (
    section: WordpressTextSectionTextSectionSection,
    device: 'mobile' | 'desktop',
): TextSectionComponentTextContentSection => {
    const { text, color } = transformTextContentGroup(section.textContent, device);
    return {
        layout: 'textContent',
        text,
        color,
    };
};

const TextSectionBlock = ({ data, ...rest }: TextSectionBlockProps) => {
    const { sections } = data.content;
    const { textColor } = data;

    const isMobile = useBreakpoint({ to: 'tablet.sm' });
    const device = isMobile ? 'mobile' : 'desktop';

    const componentData: TextSectionComponentProps = {
        sections: sections.map((section) => {
            if (
                section.layout === 'h1' ||
                section.layout === 'h2' ||
                section.layout === 'h3' ||
                section.layout === 'h4' ||
                section.layout === 'h5'
            ) {
                return transformTextSectionHeading(section, device);
            }
            if (section.layout === 'preamble') {
                return transformTextSectionPreamble(section, device);
            }
            if (section.layout === 'quote') {
                return transformTextSectionQuote(section, device);
            }
            if (section.layout === 'text_content') {
                return transformTextSectionTextContent(section, device);
            }
            throw new Error(`TextSectionBlock: sections mapping couldn't find the section.layout`);
        }),
        contentAlignment: hAlignmentMapper[data.contentHorizontalAlignment],
    };

    componentData.textColor = transformTextColor(textColor);

    // Background
    if (data.background.desktop) {
        const { type: backgroundType, data: backgroundData } = transformBackgroundGroup(data.background, device);
        if (backgroundType === 'color') {
            componentData.backgroundColor = backgroundData as BackgroundColorProps;
        } else if (backgroundType === 'image') {
            componentData.backgroundImage = backgroundData as BackgroundImageProps;
        } else if (backgroundType === 'video') {
            componentData.backgroundVideo = backgroundData as BackgroundVideoProps;
        }
    }

    // Background
    if (data.background.mobile) {
        const { type: backgroundType, data: backgroundData } = transformBackgroundGroup(data.background, device);
        if (backgroundType === 'color') {
            componentData.backgroundColor = backgroundData as BackgroundColorProps;
        } else if (backgroundType === 'image') {
            componentData.backgroundImage = backgroundData as BackgroundImageProps;
        } else if (backgroundType === 'video') {
            componentData.backgroundVideo = backgroundData as BackgroundVideoProps;
        }
    }

    if (data.buttons.length > 0) {
        componentData.buttons = data.buttons.map((button) => {
            const tButton = transformLinkButton(button.button);
            return {
                url: tButton.link,
                children: tButton.label,
                theme: tButton.theme === undefined ? 'primaryOutlined' : tButton.theme,
            };
        });
    }

    return <TextSectionComponent {...componentData} />;
};

export default TextSectionBlock;
